import React, { useRef, useState, useCallback } from 'react'
import styled from 'styled-components'
import play from 'assets/icons/asters-play-video.svg'
import pause from 'assets/icons/asters-pause-video.svg'

const HoverPlayVideo = ({ url }) => {
  const videoRef: any = useRef(null)
  const [isHovering, setIsHovering] = useState(false)
  const [videoButtonIcon, setVideoButtonIcon] = useState<string>(play)



  const playPauseVideo = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setVideoButtonIcon(pause)
      } else {
        videoRef.current.pause();
        setVideoButtonIcon(play)
      }
    }
  };

  return (
    <VideoContainer>
      <StyledVideo ref={videoRef} src={url} preload="metadata" />
      <PlayPauseButton onClick={playPauseVideo}>
            <img style={{ height: '32px' }} src={videoButtonIcon} alt="play button" />
          </PlayPauseButton>
      <HoverOverlay isVisible={!isHovering}></HoverOverlay>
    </VideoContainer>
  )
}

export default HoverPlayVideo

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  overflow: hidden;
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: none;
`
const PlayPauseButton = styled.button`
  position: absolute;
  top: 50%; 
  left: 50%;
  padding: 8px;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  border: solid 1px #00747C;
  background: #EAFCFE;
  z-index: 10;
  &:hover {
   cursor: pointer;
  }
`