import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { MediaImage } from 'components/MediaImage'
import { openConfirmModal, openCropModal } from 'components/Modal/ModalManager'
import { Text } from 'components/Text'
import { Component } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { T } from 'translation/i18n'
import { Media } from 'types'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, getMediaName } from 'utils'

interface Props {
  media: Media
  index: number
  onDelete: (index) => void
  onChangeMedia: (medias) => void
}

interface State {
  hover: boolean
  trashHover: boolean
  cropHover: boolean
}

export default class PostImg extends Component<Props, State> {
  state: State = {
    hover: false,
    trashHover: false,
    cropHover: false,
  }

  handleClickCrop = () => {
    const { activePost } = useCreatePostStore.getState()
    const mediaTags = (activePost as IPostVariant).data?.tags?.some((tag) => tag.mediaId === this.props.media._id)

    if (mediaTags) {
      return openConfirmModal({
        text: T.mediaPage.warningTagEditor,
        onConfirm: () => {
          openCropModal(this.props.media, this.props.onChangeMedia)
        },
        confirmText: T.settings.continue,
        cancelText: T.singleWord.cancel,
      })
    }

    openCropModal(this.props.media, this.props.onChangeMedia)
  }

  widthBox = 232

  render() {
    const { media, index, onDelete } = this.props
    const { trashHover, cropHover } = this.state
    return (
      <Box style={{ position: 'relative' }} center>
        {this.state.hover && (
          <ShadowBox
            onHoverStart={() => this.setState({ hover: true })}
            onHoverEnd={() => this.setState({ hover: false })}
            center
          />
        )}

        {true && !this.state.hover && (
          <ContainerButtons>
            {media && !media.mimetype?.includes('video') && (
              <Box
                id={'cropHover-' + index}
                width={32}
                height={32}
                center
                style={{
                  borderRadius: 4,
                  backgroundColor: AppStore.theme.o.lightGrey,
                  zIndex: 999999,
                }}
                onHoverStart={() => this.setState({ cropHover: true })}
                onHoverEnd={() => this.setState({ cropHover: false })}
                onClick={this.handleClickCrop}
              >
                <Box width={16.5} height={16.5} style={{}} center>
                  <Icons.crop fill={cropHover ? AppStore.theme.o.green : AppStore.theme.o.darkGrey} />
                </Box>
              </Box>
            )}
            <Box
              id={'trash-' + index}
              width={32}
              height={32}
              center
              style={{
                borderRadius: 4,
                backgroundColor: AppStore.theme.o.lightGrey,
                zIndex: 9999999,
              }}
              onHoverStart={() => this.setState({ trashHover: true })}
              onHoverEnd={() => this.setState({ trashHover: false })}
              onClick={() => onDelete(index)}
            >
              <Icons.trash
                fill={trashHover ? AppStore.theme.o.red : AppStore.theme.o.darkGrey}
                style={{ scale: 1.2 }}
              />
            </Box>
          </ContainerButtons>
        )}
        <Box
          onHoverStart={() => this.setState({ hover: true })}
          onHoverEnd={() => this.setState({ hover: false })}
          style={{
            position: 'relative',
          }}
          shadow={AppStore.theme.simpleOuterShadow}
          key={'image' + index}
          width={this.widthBox}
          borderRadius={10}
          justify="inherit"
          flex
          center
        >
          {this.state.hover &&
            (true ? (
              <Box
                absolute
                style={{ backgroundColor: AppStore.theme.o.surface, width: 80, height: 80, zIndex: 99999 }}
                center
              >
                <Icons.dragDrop />
              </Box>
            ) : (
              <SpecialButton
                style={{
                  // marginTop: '100px',
                  zIndex: 99999999,
                }}
                onClick={() => this.props.onDelete(index)}
                size="small"
                variant="delete-small"
                width={123}
                onMouseEnter={async () => this.setState({ hover: true })}
              >
                {T.postPage.delete}
              </SpecialButton>
            ))}
          <MediaImage media={media} width={this.widthBox} height={this.widthBox} fullRadius={true} showVideoControls={false} />
          {true === undefined && <MediaName style={{ padding: 10 }}>{getMediaName(media.name)}</MediaName>}
        </Box>
      </Box>
    )
  }
}

const ShadowBox = styled(Box)`
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
`

const MediaName = styled((props) => <Text {...props} />)`
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: 4em; /* Fallback for non-webkit, line-height * 2 */
  -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SpecialButton = styled(Button)`
  margin-left: 26%;
  position: absolute;
  opacity: 100;
`

const ContainerButtons = styled(Box)`
  position: fixed;
  bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`
