import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { Component } from 'react'

import { AppStore } from 'utils'

import { T } from 'translation/i18n'

interface Props {
  onCreatePost: () => void
  moveFile: () => void
  renameFile: () => void
  downloadImages: () => void
  onDeleteMedias: () => void
  editImage: () => void
  selectedMedias: any
  isModal: any
}

interface State {
  hoverIndex: number
}

export default class MediaBottomBar extends Component<Props, State> {
  state: State = {
    hoverIndex: -1,
  }
  config = [
    {
      id: 'create-post-media-page',
      text: T.postPage.createPost,
      icon: <Icons.add width={18} height={18} fill={AppStore.theme.o.primary} />,
      onClick: this.props.onCreatePost,
      color: AppStore.theme.o.primary,
      hover: AppStore.theme.o.primary_container,
    },
    {
      id: 'edit-media-button',
      text: T.mediaPage.openInEditor,
      icon: <Icons.uploadFile width={26} height={26} fill={AppStore.theme.o.black} />,
      onClick: this.props.editImage,
    },
    {
      id: 'move-media-page',
      text: T.mediaPage.move,
      icon: <Icons.move width={18} height={18} fill={AppStore.theme.o.black} />,
      onClick: this.props.moveFile,
    },
    {
      id: 'download-media-page',
      text: T.mediaPage.instal,
      icon: <Icons.download width={22} height={22} fill={AppStore.theme.o.black} />,
      onClick: this.props.downloadImages,
    },
    {
      id: 'rename Button',
      text: T.mediaPage.rename,
      icon: <Icons.modifyIcon width={24} height={24} fill={AppStore.theme.o.black} />,
      onClick: this.props.renameFile,
    },
    {
      id: 'delete-media-page',
      text: T.postPage.delete,
      icon: <Icons.trashCanSizer width={24} height={24} fill={AppStore.theme.o.error} />,
      onClick: this.props.onDeleteMedias,
      color: AppStore.theme.o.error,
      hover: AppStore.theme.o.error_container,
    },
  ]
  render() {
    return (
      <BottomBar animation="fade" hcenter>
        <Container>
          {this.config.map((item, index) => (
            <BottomBarItem
              key={item.id}
              onClick={item.onClick}
              center
              row
              gap={8}
              style={{
                color: item.color ?? AppStore.theme.o.black,
              }}
              hoverColor={item.hover}
            >
              <b>{item.text}</b>

              {item.icon}
            </BottomBarItem>
          ))}
        </Container>
      </BottomBar>
    )
  }
}

const Container = styled(Box)`
  height: 56px;
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.mainShadow};
`

const BottomBarItem = styled(Box)<{ hoverColor?: string }>`
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  line-height: normal;
  border-radius: 12px;

  &:hover {
    background: ${({ hoverColor }) => hoverColor ?? AppStore.theme.o.background};
  }
`

const BottomBar = styled(Box)`
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
`
