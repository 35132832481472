import { IWorkspaceOwner, PaginatedResult } from 'types'
import { api, responseErrorCheck } from './api'
import { AppStore } from 'utils'
import moment from 'moment-timezone'

export interface Pageable {
  offset: number,
  limit: number,
  orderBy: string,
  asc: boolean
}

export default class AstersBaseResource {
  /**
   * resource endpoint eg. /products
   */
  static endpoint

  /**
   * Pagination config (global for all resources)
   */
  static defaultPaginationConfig: Pageable = {
    offset: 0,
    limit: 300,
    orderBy: 'CREATION_DATE',
    asc: true,
  }

  /**
   * Get one Object based on the search params (eg. id: 435)
   *
   * @param {Object} userId entity identifier
   * @param {Object} userIworkspaceIdd entity identifier
   * @returns {Object}
   */
  static get(userId, workspaceId, requestConfig = undefined) {
    return api.get<IWorkspaceOwner>(`resources/${userId}/${workspaceId}`, requestConfig).then(responseErrorCheck)
  }

  /**
   * Search resources
   *
   * @param {Object} params query params
   * @returns {Array}
   */
  static search<T>(params = {}, requestConfig = undefined): Promise<T[]> {
    return api.get<T[]>(`${this.endpoint}`, params, requestConfig).then(responseErrorCheck)
  }

  /**
   * Search Resources passing params with pagination config.
   *
   * eg. const parcels = await Parcels.paginate({ received: false });
   *
   * @param {Object} searchParams query params
   * @param {Object} paginationConfig pagination config { offset, limit, groupBy, asc }
   * @returns {Array} for the current page
   */
  static paginate<T>(searchParams, paginationConfig?: Pageable | null, requestConfig?) {
    console.log(searchParams, paginationConfig)

    const paginationParams = paginationConfig || AstersBaseResource.defaultPaginationConfig

    return api
      .get<PaginatedResult<T | any>>(`${this.endpoint}`, { ...paginationParams, ...searchParams }, requestConfig)
      .then((res) => res.data)
  }

  /**
   * Total Count for resource
   *
   * @param {Object} params query params
   * @returns {number} total value
   */
  static count(params, requestConfig = undefined): Promise<number> {
    return api.get<any>(`${this.endpoint}/count`, params, requestConfig).then(responseErrorCheck)
  }

  /**
   * Configure pagination for all entities
   *
   * defaultConfig = {
   *   offset: 0,
   *   limit: 25,
   *   orderBy: 'CREATION_DATE',
   *   asc: true,
   * }
   *
   * @param {Object} cfg
   */
  static configureDefaultPagination(cfg, requestConfig = undefined) {
    if ((cfg.hasOwnProperty('offset'), requestConfig)) AstersBaseResource.defaultPaginationConfig.offset = cfg.offset

    if (cfg.hasOwnProperty('limit')) AstersBaseResource.defaultPaginationConfig.limit = cfg.limit

    if (cfg.hasOwnProperty('orderBy')) AstersBaseResource.defaultPaginationConfig.orderBy = cfg.orderBy

    if (cfg.hasOwnProperty('asc')) AstersBaseResource.defaultPaginationConfig.asc = cfg.asc
  }

  static formatMedia(obj) {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === 'object' && key !== 'image' && !!obj[key]) {
        this.formatMedia(obj[key])
      } else {
        if (key === 'image') {
          obj[key] = obj[key].filename
        }
      }
    })
    return obj
  }

  /**
   * The function `formatDate` takes in a data object, converts the date property to a new Date object,
   * adjusts the hours based on the timezone, and returns the updated data object.
   * @param {any} data - The `data` parameter is of type `any`, which means it can be any data type. It
   * is assumed to have a property called `date` which represents a date value.
   * @returns the `dataToFormat` object after formatting the date.
   */
  static formatDate(data: any) {
    const dataToFormat = data
    const newData = new Date(data.date)
    const isDST = moment.tz(newData, AppStore.workspace.timezone?.name ?? 'Europe/Rome').isDST()
    const timezone = AppStore?.workspace?.timezone?.value ?? 'UTC+00:00'
    /*newData.setHours(
      newData.getHours() - (parseInt(timezone.slice(3, 5)) - newData.getTimezoneOffset() / 60) + (isDST ? 1 : 0)
    )*/
    dataToFormat.date = newData.toISOString()
    return dataToFormat
  }

  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  static save<T = any>(data, requestConfig = undefined): Promise<T> {
    let dataToFormat
    let formattedData
    if (data.date) {
      dataToFormat = this.formatDate(data)
      formattedData = this.formatMedia(JSON.parse(JSON.stringify(dataToFormat)))
    } else {
      formattedData = this.formatMedia(JSON.parse(JSON.stringify(data)))
    }
    if (formattedData.picture === undefined) formattedData.picture = ''
    if (formattedData.id || formattedData._id) {
      return api
        .put<T>(`${this.endpoint}/${formattedData.id || formattedData._id}`, formattedData, requestConfig)
        .then(responseErrorCheck)
    } else {
      return api.post<T>(`${this.endpoint}`, formattedData, requestConfig).then(responseErrorCheck)
    }
  }

  /**
   * Delete a specific entity
   *
   * @param {String} id
   */
  static delete(id, requestConfig = undefined) {
    return api.delete(`${this.endpoint}/${id}`, requestConfig).then(responseErrorCheck)
  }

  /**
   * Creates many entities
   *
   * @param {Array} data array of entities
   * @returns {Array}
   */
  static bulkCreate(data, requestConfig = undefined) {
    return api.post(`${this.endpoint}/bulkCreate`, data, requestConfig).then(responseErrorCheck)
  }

  /**
   * Updates many entities
   *
   * @param {Array} data array of entities to update
   * @returns {Array}
   */
  static bulkUpdate(data, requestConfig = undefined) {
    return api.post(`${this.endpoint}/bulkUpdate`, data, requestConfig).then(responseErrorCheck)
  }
}
